import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Layout from "../../components/layout";
import TextSection from "../../components/text-section";
import ServiceHero from "../../components/service-hero";
import ReviewSection from "../../components/review-section";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Certifications from "../../components/certifications";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";

const EHIPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Quantum-storage-heater-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			DIImg: wpMediaItem(title: { eq: "Domestic-Installer-Logo" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
	const DIImg = data.DIImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Electric Heater Installations",
				item: {
					url: `${siteUrl}/services/electric-heater-installations`,
					id: `${siteUrl}/services/electric-heater-installations`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Electric Heater Installations | Denis Bonnici Electricians Ltd"
				description="We are registered Dimplex installers and would be happy to quote on electric/storage heater replacements and new installations."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services/electric-heater-installations`,
					title:
						"Electric Heater Installations | Denis Bonnici Electricians Ltd",
					description:
						"We are registered Dimplex installers and would be happy to quote on electric/storage heater replacements and new installations.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@denisbonnici",
					site: "@denisbonnici",
					cardType: "summary",
				}}
			/>
			<ServiceHero
				backgroundImage={heroImg}
				title="Electric Heater Installations"
			/>

			<section className="py-md-7 pb-4">
				<TextSection
					title="Electric Heater Installations"
					text={
						<div>
							<Row className="justify-content-center pb-4">
								<Col xs={4}>
									<GatsbyImage image={DIImg} alt={data.DIImg?.altText} />
								</Col>
							</Row>
							<p>
								We are registered Dimplex installers and would be happy to quote
								on electric/storage heater replacements and new installations.
							</p>
							<p>
								In January 2018 the new LOT 20 regulation came into force,
								Ecodesign directive {"(EU)"}, upgrading all existing heating to
								energy efficiency incorporating new smart technologies.
							</p>
							<p>
								{" "}
								The new storage heaters will now require both on and off peak
								supply for function.
							</p>
							<p>
								The new heaters may also assist in increasing your EPC rating,
								as landlords have to achieve an EPC rating of ‘E’ and below on
								rental properties, to achieve the minimum energy efficiency
								standards, which is now law as from April 2018.
							</p>
						</div>
					}
				/>
			</section>
			<section className="pt-6 pb-7 ">
				<Certifications />
			</section>
			<section className="pb-md-7 pb-4">
				<ReviewSection />
			</section>
		</Layout>
	);
};

export default EHIPage;
